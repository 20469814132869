import styled from '@emotion/styled';
import Frenzy from '../images/frenzy.png'
import FrenzyWelcome from '../images/frenzy-welcome.png'
import { ShowcaseItem } from '../components/ShowcaseItem';

const H2 = styled('h2')`
	color: #eee;
	text-shadow: 1px 1px 2px #222;
	line-height: 0.9em;
`

const ShowcaseImage = styled('img')`
	width: 50%;
	margin: 0.5em;
`

const FrenzyShowcaseItem = () => (
  <ShowcaseItem name="Frenzy.js" link="https://frenzyjs.themillhousegroup.com">
    <ShowcaseImage src={Frenzy} style={{ float: "right" }} alt="Frenzy = Gameplay" />
    <H2>A little taste of the 80s</H2>
    <p>From a time when 8 bits were all you needed, and a mouse was not something you'd see near a computer.</p>

    <p>Guide your plucky square sprite along the lines, avoiding the dreaded LEPTON while filling in its world.</p>
    <ShowcaseImage src={FrenzyWelcome} style={{ float: "left" }} alt="Frenzy - Welcome screen" />
    <br />
    <p>As you rise through the levels, your task gets harder. Faster Leptons, and more of them!</p>

    <p><a href="https://blog.themillhousegroup.com/search/label/frenzy.js">Follow along with the development</a> of the game, which seeks to accurately recreate the <a href="https://en.wikipedia.org/wiki/Frenzy_(1984_video_game)" target="_blank">1984 original</a>.</p>
  </ShowcaseItem>
)

export const Showcase = () => {
  return (
      <FrenzyShowcaseItem />
  )
}

