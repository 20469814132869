import { JOHN_CAREER } from './Career';
import VerticalTimeline, { VerticalTimelineEntry } from '../components/VerticalTimeline';

const CONTENT_STYLE = { color: '#151045' };
const ICON_STYLE = { background: '#151045' };

// https://github.com/stephane-monnot/react-vertical-timeline
// Would like the round dots to become elongated and to allow multiple overlapping
// elements, but hey...
export const Timeline = () => {
  return (
    <VerticalTimeline lineColor='#151045'>
      { JOHN_CAREER.map((careerJob) => 
        <VerticalTimelineEntry startDate={careerJob.startDate} endDate={careerJob.endDate}>
          <>
          <h3 className="vertical-timeline-element-title">{careerJob.companyName}</h3>
          <h4 className="vertical-timeline-element-subtitle">{careerJob.location}</h4>
          <p>
            {careerJob.techologies.map((t) => t.name).join(", ")}
          </p>
            </>
        </VerticalTimelineEntry>
      )
    }
    </VerticalTimeline>
  )
}
