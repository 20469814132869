import { useState } from 'react';
// import ReactChromakeyedImage from 'react-chromakeyed-image/lib/ChromakeyedImage';

const IMAGE_SOURCES = ["/cc6572.jpg", "/sncf.png"]

export const Sandbox = () => {
  const [findColor, setFindColor] = useState("#cfcfcf");
  const [tolerance, setTolerance] = useState(new Date().getSeconds());
  const [imageSrcIndex, setImageSrcIndex] = useState(0);


  const flipImages = () => {
    setImageSrcIndex((imageSrcIndex + 1) % 2);
  }

  return (
    <>
      <h1>H1</h1>
      <h2>H2</h2>
      <h3>H3 - Raw image:</h3>
      <img style={{width: '300px'}} src="/cc6572.jpg" />
      <h4>H4 - Chromakeyed:</h4>
      {/* <ReactChromakeyedImage 
        src={IMAGE_SOURCES[imageSrcIndex]} 
        findColor={findColor}
        replaceColor="#FF0000" 
        tolerance={tolerance}
        style={{width: "300px"}}
      /> */}
      <br/>
      <button style={{backgroundColor: "black", padding: "20px", borderRadius: "8px"}} onClick={flipImages}>Flip</button>
      <br/>

      <input style={{height: '2em'}} type="range" value={tolerance} min="0" max="255" onChange={(e) => setTolerance(parseInt(e.target.value, 10))}/>
      <h3>Tolerance: {tolerance}</h3>
      <h5>H5</h5>
      <h6>H6</h6>
      <p>This is p text</p>
      <h4>Flip Start</h4>
        
      <div className="flip-container">
        <div className="flipper">
          <div className="front">
            <img src="https://picsum.photos/id/411/300/200" alt="Front Image" />
          </div>
          <div className="back">
            <img src="https://picsum.photos/id/249/300/200" alt="Back Image" />
          </div>
        </div>
      </div>

      <h4>Flip End</h4>
  </>
  )
}
