import { format, parse } from "date-fns";

export type CareerJob = {
  companyName: string;
  location: string;
  startDate: Date;
  endDate?: Date;
  techologies: Technology[];
};

// export type Technology = {
//   name: string;
//   techType: TechType;
//   attributes?: Attribute[];
// }

export type TechType = "Language" | "Database" | "Platform" | "Framework"

export interface Technology<T extends TechType> {
  name: string;
  techType: T;
}


type LangAttributes = "Functional" | "Object-Oriented" | "JVM" | "Server-Side" | "Client-Side" | "Full-Stack"
type DBAttributes = "Relational" | "NoSQL"
type PlatformAttributes = "Serverless" | "Containerised"
type FrameworkAttributes = "Frontend" | "Backend"

interface LanguageTech extends Technology<"Language"> {
    attributes?: LangAttributes[]
}

interface DatabaseTech extends Technology<"Database"> {
    attributes?: DBAttributes[]
}
interface PlatformTech extends Technology<"Platform"> {
    attributes?: PlatformAttributes[]
}
interface FrameworkTech extends Technology<"Framework"> {
    attributes?: FrameworkAttributes[]
}

const DT_FORMAT = "MMM yyyy"

export const parseDt = (s: string):Date => {
  return parse(s, DT_FORMAT, new Date())
}
export const renderDt = (d: Date):string => {
  return format(d, DT_FORMAT)
}

const C: LanguageTech = { name: "C", techType: "Language", attributes:["Server-Side"]}
const CPLUSPLUS: LanguageTech = { name: "C++", techType: "Language", attributes:["Server-Side", "Object-Oriented"]}
const CSHARP: LanguageTech = { name: "C#", techType: "Language", attributes:["Server-Side", "Object-Oriented"]}
const JAVA: LanguageTech = { name: "Java", techType: "Language", attributes:["Server-Side", "JVM", "Object-Oriented"]}
const SCALA: LanguageTech = { name: "Scala", techType: "Language", attributes:["Server-Side", "JVM", "Functional", "Object-Oriented"]}
const CLOJURE: LanguageTech = { name: "Clojure", techType: "Language", attributes:["Server-Side", "JVM", "Functional"]}
const JAVASCRIPT: LanguageTech = { name: "JavaScript", techType: "Language", attributes:["Full-Stack", "Functional", "Object-Oriented"]}
const TYPESCRIPT: LanguageTech = { name: "TypeScript", techType: "Language", attributes:["Full-Stack", "Functional", "Object-Oriented"]}
const TERRAFORM: Technology = { name: "Terraform", techType: "Language"}
const GRAPHQL: LanguageTech = { name: "GraphQL", techType: "Language"}
const PLSQL: LanguageTech = { name: "PL/SQL", techType: "Language"}
const SQL: LanguageTech = { name: "SQL", techType: "Language"}
const ORACLE: DatabaseTech = { name: "Oracle", techType: "Database", attributes:["Relational"]}
const MONGODB: DatabaseTech = { name: "MongoDB", techType: "Database", attributes:["No-SQL"]}
const DYNAMODB: DatabaseTech = { name: "DynamoDB", techType: "Database", attributes:["No-SQL"]}
const POSTGRES: DatabaseTech = { name: "PostgreSQL", techType: "Database", attributes:["Relational"]}
const AWS: PlatformTech = { name: "AWS", techType: "Platform"}

export const JOHN_CAREER: CareerJob[] = [
{
  companyName: "Agilent Technologies",
  location: "Melbourne, AU",
  startDate: parseDt("Jan 2000"),
  endDate: parseDt("Mar 2003"),
  techologies: [C, CPLUSPLUS]
},
{
  companyName: "MTData",
  location: "Melbourne, AU",
  startDate: parseDt("Apr 2003"),
  endDate: parseDt("Jan 2005"),
  techologies: [C, CSHARP]
},
{
  companyName: "DWS (Origin Energy)",
  location: "Melbourne, AU",
  startDate: parseDt("Feb 2005"),
  endDate: parseDt("Feb 2007"),
  techologies: [JAVA, PLSQL, SQL, ORACLE]
},
{
  companyName: "Volutio",
  location: "Chippenham, UK",
  startDate: parseDt("Feb 2007"),
  endDate: parseDt("Feb 2008"),
  techologies: [JAVA, JAVASCRIPT]
},
{
  companyName: "Staellium",
  location: "London, UK",
  startDate: parseDt("Feb 2008"),
  endDate: parseDt("Jun 2008"),
  techologies: [JAVA, JAVASCRIPT]
},
{
  companyName: "Icom",
  location: "London, UK",
  startDate: parseDt("Jul 2008"),
  endDate: parseDt("Dec 2009"),
  techologies: [JAVA, JAVASCRIPT, SQL]
},
{
  companyName: "Reece",
  location: "Melbourne, AU",
  startDate: parseDt("Dec 2009"),
  endDate: parseDt("Dec 2010"),
  techologies: [JAVA, SQL]
},
{
  companyName: "Sensis",
  location: "Melbourne, AU",
  startDate: parseDt("Jan 2011"),
  endDate: parseDt("Aug 2011"),
  techologies: [JAVA, JAVASCRIPT]
},
{
  companyName: "Open Universities Australia",
  location: "Melbourne, AU",
  startDate: parseDt("Sep 2011"),
  endDate: parseDt("Feb 2013"),
  techologies: [JAVA, JAVASCRIPT]
},
{
  companyName: "Australia Post Digital Mailbox",
  location: "Melbourne, AU",
  startDate: parseDt("Mar 2013"),
  endDate: parseDt("Mar 2015"),
  techologies: [JAVA, JAVASCRIPT, SCALA, CLOJURE]
},
{
  companyName: "Sensis",
  location: "Melbourne, AU",
  startDate: parseDt("Mar 2015"),
  endDate: parseDt("Mar 2016"),
  techologies: [JAVA, JAVASCRIPT, SCALA, MONGODB]
},
{
  companyName: "Bridge",
  location: "Melbourne, AU",
  startDate: parseDt("Mar 2016"),
  endDate: parseDt("Jan 2018"),
  techologies: [JAVASCRIPT, SCALA, MONGODB, GRAPHQL]
},
{
  companyName: "Shroogal",
  location: "Melbourne, AU",
  startDate: parseDt("Feb 2018"),
  endDate: parseDt("Sep 2018"),
  techologies: [JAVASCRIPT, DYNAMODB, GRAPHQL, AWS]
},
{
  companyName: "Homepass",
  location: "Melbourne, AU",
  startDate: parseDt("Sep 2018"),
  endDate: parseDt("Aug 2019"),
  techologies: [JAVASCRIPT, MONGODB, GRAPHQL, AWS]
},
{
  companyName: "OVO Energy Australia",
  location: "Melbourne, AU",
  startDate: parseDt("Aug 2019"),
  endDate: parseDt("Nov 2024"),

  techologies: [SCALA, TYPESCRIPT, MONGODB, GRAPHQL, AWS, TERRAFORM]
},
{
  companyName: "Kaluza Australia",
  location: "Melbourne, AU",
  startDate: parseDt("Nov 2024"),

  techologies: [TYPESCRIPT, POSTGRES, GRAPHQL]
},
]
