import styled from '@emotion/styled';
import Frenzy from '../images/frenzy.png'
import FrenzyWelcome from '../images/frenzy-welcome.png'
import Cardle from '../images/cardle-gameplay.png'
import CardleWelcome from '../images/cardle-welcome.png'
import { ShowcaseItem } from '../components/ShowcaseItem';
import Swiper from 'react-id-swiper';
import 'swiper/swiper-bundle.css';

const H2 = styled('h2')`
	color: #eee;
	text-shadow: 1px 1px 2px #222;
	line-height: 0.9em;
`

const ShowcaseImage = styled('img')`
	width: 50%;
	margin: 0.5em;
`

const FrenzyShowcaseItem = () => (
  <ShowcaseItem name="Frenzy.js" link="https://frenzyjs.themillhousegroup.com">
    <ShowcaseImage src={Frenzy} style={{ float: "right" }} alt="Frenzy = Gameplay" />
    <H2>A little taste of the 80s</H2>
    <p>From a time when 8 bits were all you needed, and a mouse was not something you'd see near a computer.</p>

    <p>Guide your plucky square sprite along the lines, avoiding the dreaded LEPTON while filling in its world.</p>
    <ShowcaseImage src={FrenzyWelcome} style={{ float: "left" }} alt="Frenzy - Welcome screen" />
    <br />
    <p>As you rise through the levels, your task gets harder. Faster Leptons, and more of them!</p>

    <p><a href="https://blog.themillhousegroup.com/search/label/frenzy.js">Follow along with the development</a> of the game, which seeks to accurately recreate the <a href="https://en.wikipedia.org/wiki/Frenzy_(1984_video_game)" target="_blank">1984 original</a>.</p>
  </ShowcaseItem>
)

const CardleShowcaseItem = () => (
  <ShowcaseItem name="Cardle" link="https://cardle.themillhousegroup.com">
    <ShowcaseImage src={Cardle} style={{ float: "right" }} alt="Cardle - Gameplay" />
    <H2>It's Wordle - For Cars!</H2>
    <p>Test your knowledge of the cars of the world.</p>

    <p>Using just a few parameters, can you deduce a single car from a starting set of over 175?</p>
    <ShowcaseImage src={CardleWelcome} style={{ float: "left" }} alt="Cardle - Welcome screen" />
    <br />
    <p>You can also read a little bit of <a href="https://blog.themillhousegroup.com/2022/06/introducing-cardle.html">behind-the-scenes</a>
	    information about the game's development, if that is of interest.</p>
  </ShowcaseItem>
)

const params = {
  effect: 'coverflow',
  grabCursor: true,
  centeredSlides: true,
  slidesPerView: 'auto',
  coverflowEffect: {
    rotate: 50,
    stretch: 0,
    depth: 100,
    modifier: 1,
    slideShadows: true
  },
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  }
}

// https://scroll-driven-animations.style/demos/cover-flow/css/

export const Showcase = () => {
  return (
    <Swiper {...params}>
      <FrenzyShowcaseItem />
      <CardleShowcaseItem />
    </Swiper>
  )
}
