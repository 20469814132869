import React from 'react';
import './App.css';
import Links from './components/Links';

import { Sandbox } from './pages/Sandbox';
import { Showcase } from './pages/Showcase';
import { Showcase as Showcase2 } from './pages/Showcase2';
import { Login } from './pages/Login';
import { Timeline } from './pages/Timeline';
import { Gantt } from './pages/Gantt';
import { Dashboard } from './pages/Dashboard';

const renderArticle = (pathname: string) => {
	switch (pathname) {
		case "/sandbox":
			return <Sandbox />
		case "/timeline":
			return <Timeline />
		case "/gantt":
			return <Gantt />
		case "/login":
			return <Login />
		case "/dashboard":
			return <Dashboard />
		case "/showcase":
			return <Showcase2 />
		default:
			return <Showcase />
	}
}

const App = () => {
	return (
		<div className="App">
			<header style={{ display: 'flex', justifyContent: 'space-between', zIndex: 10, position: 'relative' }}>
					<div className="NameHolder"><a className="glowup" style={{display: "inline-block" }} href="/">The <b>Millhouse</b> Group</a></div>
					<Links />
			</header>
			<article style={{ position: 'relative', zIndex: 0, paddingBottom: '1em' }}>
				{ renderArticle(window.location.pathname) }
			</article>
		</div>
	);
}

export default App;
