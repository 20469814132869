import { ReactNode } from "react";
import styled from "@emotion/styled";
import { renderDt } from '../pages/Career';

// https://scroll-driven-animations.style/demos/image-reveal/css/
const RevealingImage = styled('div')`
            @keyframes reveal {
                from {
                    opacity: 0;
                    clip-path: inset(45% 20% 45% 20%);
                }

                to {
                    opacity: 1;
                    clip-path: inset(0% 0% 0% 0%);
                }
            }

            .revealing-image {
                view-timeline-name: --revealing-image;
                view-timeline-axis: block;
                animation: linear reveal both;
                animation-timeline: --revealing-image;
                animation-range: entry 25% cover 50%;
            }
            `;

export const TimelineEntryContainer = styled('div')`
`;

export const TimelineEntryDate = styled('div')`
  font-weight: bold;
`;

type VTEProps = {
  startDate: Date;
  endDate?: Date;
  children: VerticalTimelineEntry[]
}  

export const TimelineVerticalLine = styled('div')`
  background-color: pink;
`;

export const VerticalTimelineEntry = (props: VTEProps) => {
  const { startDate, endDate, children } = props;
  return (
      <>
          <TimelineEntryDate>
            {renderDt(startDate)} - {endDate ? (renderDt(endDate)): "Present"}
          </TimelineEntryDate>
          <TimelineVerticalLine />
          <div className="entry">
            {children}
          </div>       
      </>
    )
}


type Props = {
  lineColor: string;
  children: ReactNode | ReactNode[]
}  

export const TimelineContainer = styled('div')`
  color: #eee;
  width: 75vw;
  max-width: 600px;
  margin: 3em auto;
  opacity: .9;
  display: flex;
  flex-direction: column;
`;


const VerticalTimeline = (props: Props) => {
  return (
              <RevealingImage>
    <TimelineContainer>
      {props.children.map((kid) => <div className="revealing-image">{kid}</div>)}
    </TimelineContainer>
                          </RevealingImage>
  )
}

export default VerticalTimeline;
